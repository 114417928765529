import styled from "styled-components";
import { BackgroundColor } from "../theme";
import { Typography } from "antd";

import audit_card_1 from "../assets/audit-1.png";
import audit_card_2 from "../assets/audit-2.png";
import audit_card_3 from "../assets/audit-3.png";
import audit_card_4 from "../assets/audit-4.png";
import audit_card_5 from "../assets/audit-5.png";
import audit_card_6 from "../assets/audit-6.png";
import audit_card_7 from "../assets/AFin-Logo.png";
import audit_card_8 from "../assets/Jrepo2-logo.png";
import audit_card_9 from "../assets/MVP.png";
import audit_card_10 from "../assets/mr-fox.png";
import marExchange from "../assets/mar.png";

const { Title } = Typography;

const HeroStyled = styled.div`
  background-color: ${BackgroundColor};
  padding-top: 100px;
  /* min-height: 100vh; */
  color: white;
`;

const CardImage = styled.div`
  border-radius: 5px;
  width: 100%;
  height: 150px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 9px 0 rgb(0 0 0 / 32%);
  transition: transform 0.3s;

  :hover {
    transform: scale(103%);
  }
`;

const FlexCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 15px;
  justify-content: center;

  @media (max-width: 500px) {
    flex-direction: column;

    div {
      width: 100% !important;
    }
  }
`;

const Timeline = () => {
  return (
    <div
      style={{
        marginTop: "80px",
        marginBottom: "30px",
      }}
      className="timeline"
    >
      <div id="process">
        <div className="first">
          <h2>CONTACT</h2>
          <p>You specify an audit-ready code commit to us</p>
        </div>
        <div>
          <h2>QUOTE</h2>
          <p>We'll estimate timeline and make a quote</p>
        </div>
        <div>
          <h2>AUDIT</h2>
          <p>We start the audit</p>
        </div>
        <div>
          <h2>REPORT</h2>
          <p>We privately send the report to your team</p>
        </div>
        <div>
          <h2>FIXES</h2>
          <p>Your team fixes the issues</p>
        </div>
        <div className="last">
          <h2>PUBLISH</h2>
          <p>We examine your fixes, update and publish the report (optional)</p>
        </div>
      </div>
    </div>
  );
};

const Hero = () => {
  return (
    <HeroStyled>
      <div className="container">
        <Title style={{ color: "#fff" }} level={2}>
          ThaiShield, the security audits service by ThaiChain.
        </Title>
        <p
          style={{
            maxWidth: "700px",
          }}
        >
          ThaiShield verifies that your distributed systems work as intended by
          performing an audit. Our engineers fully review your system’s
          architecture and codebase, and then write a thorough report that
          includes actionable feedback for every issue found.
        </p>
        <Timeline />
        <div
          style={{
            position: "relative",
            top: "40px",
          }}
        >
          <Title
            style={{
              textAlign: "center",
              color: "white",
              marginBottom: "35px",
            }}
            level={5}
          >
            OUR CLIENTS
          </Title>
          <FlexCard>
            <div
              style={{
                width: "30%",
              }}
            >
              <a
                rel="noreferrer"
                href="https://seeder.finance/"
                target="_blank"
              >
                <CardImage
                  style={{
                    background: `url(${audit_card_1})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></CardImage>
              </a>
            </div>
            <div
              style={{
                width: "30%",
              }}
            >
              <a
                rel="noreferrer"
                href="https://github.com/thaichain/thaishield-reports/blob/main/Ploutoz%20Audit%20Report.pdf"
                target="_blank"
              >
                <CardImage
                  style={{
                    background: `url(${audit_card_2})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></CardImage>
              </a>
            </div>
            <div
              style={{
                width: "30%",
              }}
            >
              <a rel="noreferrer" href="https://mar.exchange/" target="_blank">
                <CardImage
                  style={{
                    background: `url(${marExchange})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></CardImage>
              </a>
            </div>
            <div
              style={{
                width: "30%",
              }}
            >
              <a
                rel="noreferrer"
                href="https://github.com/thaichain/thaishield-reports/blob/main/Afin%20Token%20Audit.pdf"
                target="_blank"
              >
                <CardImage
                  style={{
                    background: `url(${audit_card_7})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></CardImage>
              </a>
            </div>
            <div
              style={{
                width: "30%",
              }}
            >
              <a
                rel="noreferrer"
                href="https://github.com/MVP-Coin/contract-v2/blob/master/MVPCoin-v2-Audit.pdf"
                target="_blank"
              >
                <CardImage
                  style={{
                    background: `url(${audit_card_9})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></CardImage>
              </a>
            </div>
            <div
              style={{
                width: "30%",
              }}
            >
              <a
                rel="noreferrer"
                href="https://dopple.finance/"
                target="_blank"
              >
                <CardImage
                  style={{
                    background: `url(${audit_card_3})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></CardImage>
              </a>
            </div>
            <div
              style={{
                width: "30%",
              }}
            >
              <a
                rel="noreferrer"
                href="https://safebsc.finance/"
                target="_blank"
              >
                <CardImage
                  style={{
                    background: `url(${audit_card_4})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></CardImage>
              </a>
            </div>
            <div
              style={{
                width: "30%",
              }}
            >
              <CardImage
                style={{
                  background: `url(${audit_card_8})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></CardImage>
            </div>
            <div
              style={{
                width: "30%",
              }}
            >
              <CardImage
                style={{
                  background: `url(${audit_card_10})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></CardImage>
            </div>
            <div
              style={{
                width: "30%",
              }}
            >
              <CardImage
                style={{
                  background: `url(${audit_card_5})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></CardImage>
            </div>
            <div
              style={{
                width: "30%",
              }}
            >
              <CardImage
                style={{
                  background: `url(${audit_card_6})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></CardImage>
            </div>
          </FlexCard>
        </div>
      </div>
    </HeroStyled>
  );
};

export default Hero;
