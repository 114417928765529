import { useState } from 'react'
import Logo from '../assets/logo.svg'
import styled from 'styled-components'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { GiHamburgerMenu } from 'react-icons/gi'
import { GrClose } from 'react-icons/gr'
import { Dropdown, Menu } from 'antd'

const MainNavbar =  styled.div`
  min-height: 70px;
  background-color: #ffffff;
`

const UlStyled = styled.ul`
  float: right;
  margin-top: 23px;

  @media (max-width: 500px) {
    display: none;
  }
`

const LiStyled = styled.li`
  display: inline;
  margin-left: 50px;
  cursor: pointer;
`

const MdKeyboardArrowDownStyled = styled(MdKeyboardArrowDown) `
  vertical-align: baseline;
  position: relative;
  top: 5px;
`

const menu = (
  <Menu>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        1st menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
        2nd menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
        3rd menu item
      </a>
    </Menu.Item>
  </Menu>
);

const BurderButton = styled.div`
  float: right;
  font-size: 18pt;
  margin-top: 22px;
  display: none;

  @media (max-width: 500px) {
    display: block;
  }
`

const MobileUl = styled.ul`
  padding-left: 8px;
  li {
    list-style: none;
    cursor: pointer;
  }
  li:not(:last-child) {
    padding-bottom: 10px;
  }
`

const Navbar = () => {

  const [showMenu, setShowMenu] = useState(false)

  const MobileMenu = () => {
    return (
      <div style={{
        minHeight: '80px',
        width: '100%',
        paddingTop: '20px',
        paddingBottom: '20px'
      }}>
        <MobileUl>
          <li>
            <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
              <span>Product <MdKeyboardArrowDownStyled /></span>
            </Dropdown>
          </li>
          <li>
            Security Audits
          </li>
          <li>
            Learn
          </li>
          <li>
            Company
          </li>
          <li>
            Blog
          </li>
        </MobileUl>
      </div>
    )
  }
  /*
  return (
    <div id="nav-bar">
      <MainNavbar>
        <div className="container">
          <img style={{ marginTop: '13px', width: '180px' }} src={Logo} alt="logo" />
          <BurderButton>
            {!showMenu && <GiHamburgerMenu
              onClick={() => {
                setShowMenu(!showMenu)
              }}
            />}
            { showMenu && <GrClose
              onClick={() => {
                setShowMenu(!showMenu)
              }}
            />}
          </BurderButton>
          { showMenu && <MobileMenu /> }
          <UlStyled>
            <LiStyled>
              <Dropdown overlay={menu} placement="bottomLeft" arrow>
                <span>Product <MdKeyboardArrowDownStyled /></span>
              </Dropdown>
            </LiStyled>
            <LiStyled>
              Security Audits
            </LiStyled>
            <LiStyled>
              <Dropdown overlay={menu} placement="bottomLeft" arrow>
                <span>Learn <MdKeyboardArrowDownStyled /></span>
              </Dropdown>
            </LiStyled>
            <LiStyled>
              <Dropdown overlay={menu} placement="bottomLeft" arrow>
                <span>Company <MdKeyboardArrowDownStyled /></span>
              </Dropdown>
            </LiStyled>
            <LiStyled>
              Blog
            </LiStyled>
          </UlStyled>
        </div>
      </MainNavbar>
    </div>
  )
  */
  return (
    <div id="nav-bar">
      <MainNavbar>
        <div className="container">
          <img style={{ marginTop: '13px', width: '180px' }} src={Logo} alt="logo" />
        </div>
      </MainNavbar>
    </div>
  )
}



export default Navbar
