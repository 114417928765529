import styled from 'styled-components'
import { Typography } from 'antd'

const { Title } = Typography

const FAQstyled = styled.div`
  min-height: 100vh;
  padding-top: 100px;
`

const ContentContainer = styled.div`
  width: 600px;
  margin: 0 auto;

  @media (max-width: 500px) {
    width: 100%;
  }
`

const TitleThinText = styled(Title)`
  padding-top: 34px;
  font-weight: 400;
`

const ContentP = styled.p`
  padding-bottom: 34px;
`

const ContentPB = styled(ContentP)`
  border-bottom: solid 1px #E1E1E2;
`

const Faq = () => {
  return (
    <FAQstyled>
      <div className="container">
        <Title style={{ textAlign: 'center' }} level={2}>FAQ</Title>
        <ContentContainer>
          <TitleThinText level={2}>What will I find in the audit report?</TitleThinText>
          <ContentPB>
            The report outlines potential problems in the code with actionable recommendations to guard against potential attack vectors, together with a general analysis of the system dynamics, reflecting both state-of-the-art security patterns and opportunities for improvement regarding the project's overall quality and maturity.
          </ContentPB>
          <TitleThinText level={2}>Is the report private?</TitleThinText>
          <ContentPB>
            Yes. We privately send the report to your team so they can address the issues we found. Publishing the report after your team fixes the issues is optional but strongly recommended as a way to contribute to the ecosystem’s security. We can work with you on a disclosure strategy.
          </ContentPB>
          <TitleThinText level={2}>Which technologies do you work with?</TitleThinText>
          <ContentPB>
            We have expertise across the whole stack: from languages and compilers to smart contract systems, protocols, and applications. Our audit portfolio spans distributed payment networks, financial structures, and governance systems.
          </ContentPB>
          <TitleThinText level={2}>
            How do I know when I'm ready for an external audit?
          </TitleThinText>
          <ContentP>
          We have audited systems in different stages of development and production, but we have found that the best outcomes, and most interesting findings happen when the code has been tested and documented and is ready for deployment. This checklist lists a few basic quality measures that you should consider before you hand your next project over for an external audit.
          </ContentP>
        </ContentContainer>
      </div>
    </FAQstyled>
  )
}

export default Faq