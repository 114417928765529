import styled from 'styled-components'
import LogoWhite from '../assets/logo_white.svg'
import { Typography } from 'antd'  
import { text2, BackgroundColor } from '../theme'

const { Title } = Typography

const MainFooter = styled.div`
  background: ${BackgroundColor};
  color: white;
  padding: 74px 0 20px;
  margin-bottom: 0;
`

const NewTitle = styled(Title)`
  margin: 0;
  color: #fff !important;
`

const UlStyled = styled.ul`
  padding-left: 0;
  padding-right: 10px;
`

const LinkLiStyled = styled.li`
  list-style: none;
  margin-right: 0px;
  padding-bottom: 10px;

  a {
    color: ${text2};
    text-decoration: none;
  }

  a:hover {
    color: white;
  }
`

interface FooterItem {
  title: string;
  links: Link[];
}

interface Link {
  text: string;
  to: string;
}

const items: FooterItem[] = [
  {
    title: 'Products',
    links: [
      {
        text: 'Contracts',
        to: '#'
      },
      {
        text: 'Defender',
        to: '#'
      }
    ] as Link[]
  },
  {
    title: 'Security',
    links: [
      {
        text: 'Audits',
        to: '#'
      },
      {
        text: 'Request a security audit',
        to: '#'
      }
    ] as Link[]
  },
  {
    title: 'Learn',
    links: [
      {
        text: 'Docs',
        to: '#'
      },
      {
        text: 'Community Forum',
        to: '#'
      },
      {
        text: 'Ethernaut',
        to: '#'
      }
    ] as Link[]
  },
  {
    title: 'Company',
    links: [
      {
        text: 'About Us',
        to: '#'
      },
      {
        text: 'Jobs',
        to: '#'
      },
      {
        text: 'Githubs',
        to: '#'
      }
    ] as Link[]
  }
]

const FlexFooter = styled.div`
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column;
    text-align: center;
  }
`

const FlexItemFooter = styled.div`
  display: flex;

  @media (max-width: 350px) {
    flex-direction: column;
    text-align: center;
  }
`

const Footer = () => {
  return (
    <MainFooter>
      <div className="container">
        
      </div>
    </MainFooter>
  )
}

export default Footer