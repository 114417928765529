import { useState } from 'react'
import styled from 'styled-components'
import SecurityLogo from '../assets/security.svg'
import { BackgroundColor } from '../theme'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

const StickNavbarStyled = styled.div`
  height: 70px;
  width: 100%;
  z-index: 1000;
  background-color: ${BackgroundColor};
  border-bottom: solid 1px #a77e41;
`

const RequestLink = styled.a`
  float: right;
  margin-top: 25px;
  color: white;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  :hover {
    margin-top: 24px;
    color: white;
  }
`

const StickyNavbar = () => {

  const [attachNav, setAttachNav] = useState(false)

  useScrollPosition(
    ({ currPos }) => {
      const pos = Math.abs(currPos.y)
      setAttachNav(pos > 70)
    },
    [attachNav],
    undefined,
    false,
    100
  )

  return (
    <div id="sticky-navbar">
      <StickNavbarStyled
        style={{
          position: attachNav ? 'fixed': 'initial',
          top: attachNav ? '0': ''
        }}
      >
        <div className="container">
          <img style={{ marginTop: '15px' }} src={SecurityLogo} alt="security" />
          <RequestLink href="https://facebook.com/thaichain.io" target="_blank">Request an audit</RequestLink>
        </div>
      </StickNavbarStyled>
    </div>
  )
}

export default StickyNavbar