import React from 'react'
import 'antd/dist/antd.css'
import './App.css'
import Navbar from './components/Navbar'
import StickyNavbar from './components/StickyNavbar'
import Hero from './components/Hero'
import Faq from './components/Faq'
//import Project from './components/Project'
import Footer from './components/Footer'


function App() {
  return (
    <div className="App">
      <Navbar />
      <StickyNavbar />
      <Hero />
      <Faq />
      <Footer />
    </div>
  );
}

export default App
